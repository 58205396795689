import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { Langs } from 'lb-types'

@Injectable( {
    providedIn: 'root'
} )
export class ConfigService {

    constructor(
        @Inject(DOCUMENT) private document: Document
    ) {

    }

    public webappName: string = 'LB_WEB_APP_ADMIN'
    public googleProjectId: string = 'lineberty-uat-3db57c'
    public googleMapApiUrl: string = 'https://maps.googleapis.com/maps/api/js?libraries=places&key='
    public googleMapApiKey: string = 'AIzaSyBAQlxq1VXX2g7vB1wj7ezwpxInMl-7UTs'
    public linebertyAdminUrl: string = this.document.location.hostname !== 'localhost' ? this.document.location.origin + '/' : 'https://admin2.winter.lineberty.net/'
    public linebertyBookingWebappUrl: string = 'https://uat-booking.lineberty.net/'
    // public linebertyBookingWebappUrl: string = 'http://localhost:4400/'
    public linebertyValidationWebappUrl: string = 'https://uat-validation.lineberty.net/'
    // public linebertyValidationWebappUrl: string = 'http://localhost:4300/'
    public linebertyDisplayWebappUrl: string = 'https://uat-display.lineberty.net/'
    public linebertyBookingApi: string = 'https://api-booking.winter.lineberty.net/'
    public linebertyValidationApi: string = 'https://api-validation.winter.lineberty.net/'
    public linebertyDisplayApi: string = 'https://api-display.winter.lineberty.net/'
    public linebertyMobileWebAppUrl: string = 'https://webapp.winter.lineberty.net/'

    public availableLinebertyWebappSubDomain = [
        { type: 'booking', subDomain: 'uat-booking.lineberty.net', method: 'https', name: { 'fr_FR': 'Interface de prise de tickets par défaut' } },
        { type: 'display', subDomain: 'uat-display.lineberty.net', method: 'https', name: { 'fr_FR': 'Interface d\'affichage de tickets par défaut' } },
        { type: 'validation', subDomain: 'uat-validation.lineberty.net', method: 'https', name: { 'fr_FR': 'Interface de validation de tickets par défaut' } },
        { type: 'validation', subDomain: 'uat-sso-validation.lineberty.net', method: 'https', name: { 'fr_FR': 'SSO Lineberty' } }
    ]
    public defaultBookingWebAppSubDomain = this.availableLinebertyWebappSubDomain[0]
    public defaultDisplayWebAppSubDomain = this.availableLinebertyWebappSubDomain[1]
    public defaultValidationWebAppSubDomain = this.availableLinebertyWebappSubDomain[2]
    public defaultValidationWebAppSSOSubDomain = this.availableLinebertyWebappSubDomain[3]

    public defaultValidationThemeMobileOperatorValidationProfiles = [ '607746414f5d824dfa496cec', '607746424f5d824dfa496ced' ]

    private linebertyServer: string = 'https://gw.winter.lineberty.net/'
    private appAdmin: string = 'appadmin/'
    private appAuth: string = 'appauth/'
    private appBooking: string = 'appbooking/'
    private appBookingRequest: string = 'appbookingrequest/'
    private appReport: string = 'appreport/'
    private appTimetable: string = 'apptimetable/'
    private appDeviceManager: string = 'appdevicemanager/'
    private appEndpoint: string = 'appendpoint/'
    private appSmsPusher: string = 'appsmspusher/'
    private appSmsResponse: string = 'appsmsresponse/'
    private appPushRouter: string = 'apppushrouter/'
    private appMailPusher: string = 'appmailpusher/'
    private callExternalPusher: string = 'externalpusher/'
    public httpUrl = {
        auth: {
            'login': this.linebertyServer + this.appAuth + 'api/api_admin/v1/auth/login'
            // 'login': 'http://localhost:3044/api/api_admin/v1/auth/login'
        },
        token: {
            'refreshToken' : this.linebertyServer + this.appAuth + 'api/api_admin/v1/auth/refreshToken'
        },
        cache: {
            'getAllKeys' : this.linebertyServer + this.appBooking + 'api/api_admin/v1/cache',
            'getKeysValues' : this.linebertyServer + this.appBooking + 'api/api_admin/v1/cache/values',
            'setKeysValues' : this.linebertyServer + this.appBooking + 'api/api_admin/v1/cache',
            'deleteKeys' : this.linebertyServer + this.appBooking + 'api/api_admin/v1/cache',
            'deleteAllKeys' : this.linebertyServer + this.appBooking + 'api/api_admin/v1/cache/all'
        },
        companies: {
            'getCompaniesPlacesAndQueues': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/companiesPlacesAndQueues/',
            'getCompanies': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/companies/',
            'setCompanies': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/companies/',
            'createCompanies': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/companies/',
            'deleteCompanies': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/companies/'
        },
        places: {
            'getPlaces': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/places/',
            'setPlaces': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/places/',
            'createPlaces': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/places/',
            'deletePlaces': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/places/',
            'getPlacesConfig': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/places/configurations/',
            'setPlacesConfig': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/places/configurations/'
        },
        subPlaces: {
            'getSubPlaces': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/subPlaces/',
            'getSubPlacesFromPlaceId': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/places/{placeId}/subPlaces',
            'setSubPlaces': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/subPlaces/',
            'createSubPlaces': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/subPlaces/',
            'deleteSubPlaces': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/subPlaces/'
        },
        categories: {
            'getCategories': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/categories/',
            'setCategories': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/categories/',
            'createCategories': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/categories/',
            'deleteCategories': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/categories/'
        },
        queues: {
            'getQueues': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/queues/',
            'setQueues': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/queues/',
            'createQueues': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/queues/',
            'deleteQueues': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/queues/',
            'copyQueueConfiguration': this.linebertyServer + this.appBooking + 'api/api_admin/v1/queues/configurations/byCopy',
            'getAppBookingQueuesConfiguration': this.linebertyServer + this.appBooking + 'api/api_admin/v1/queues/configurations/',
            'getAppBookingQueuesConfigurationLogger': this.linebertyServer + this.appBooking + 'api/api_admin/v1/queues/configurations/logs',
            'setAppBookingQueuesConfiguration': this.linebertyServer + this.appBooking + 'api/api_admin/v1/queues/configurations/',
            'getAppTimetableQueuesConfiguration': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/queues/configurations/',
            'setAppTimetableQueuesConfiguration': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/queues/configurations/',
            'copyAppTimetableQueuesConfiguration': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/queues/configurations/byCopy',
            'getAppAdminQueuesConfiguration': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/queues/configurations/',
            'setAppAdminQueuesConfiguration': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/queues/configurations/',
            'copyAppAdminQueuesConfiguration': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/queues/configurations/byCopy',
            'createAppAdminQueuesConfiguration': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/queues/configurations/',
            'getAppointmentTypes': this.linebertyServer + this.appBooking + 'api/api_admin/v1/queues/appointmentTypes/',
            'createAppointmentTypes': this.linebertyServer + this.appBooking + 'api/api_admin/v1/queues/appointmentTypes/',
            'setAppointmentTypes': this.linebertyServer + this.appBooking + 'api/api_admin/v1/queues/appointmentTypes/',
            'deleteAppointmentTypes': this.linebertyServer + this.appBooking + 'api/api_admin/v1/queues/appointmentTypes/',
            'cleanQueuesTickets': this.linebertyServer + this.appBooking + 'api/api_admin/v1/queues/tickets/',
            'getDaysWithTicket': this.linebertyServer + this.appBooking + 'api/api_admin/v1/queues/tickets/daysWithTickets',
            // 'getDaysWithTicket': 'http://localhost:3041/api/api_admin/v1/queues/tickets/daysWithTickets',
            'getTickets': this.linebertyServer + this.appBooking + 'api/api_admin/v1/queues/tickets/',
            // 'getTickets': 'http://localhost:3041/api/api_admin/v1/queues/tickets/',
            'getTicketsSSE': this.linebertyServer + this.appBooking + 'api/api_admin/v1/queues/tickets/sse'
            // 'getTicketsSSE': 'http://localhost:3041/api/api_admin/v1/queues/tickets/sse'
        },
        translations: {
            'getQueueTranslations': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/translations',
            'createQueueTranslations': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/translations',
            'setQueueTranslations': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/translations',
            'deleteQueueTranslations': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/translations',
        },
        placesTimeslots: {
            'getPlacesTimeslots': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/places/',
            'setPlacesPlanningTimeslots': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/places/planning',
            'setPlacesExceptionsTimeslots': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/places/exception',
            'delPlacesPlanningTimeslots': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/places/planning',
            'delPlacesExceptionsTimeslots': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/places/exception'
        },
        queueBookingTimeslots: {
            'getQueueBookingTimeslots': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/queues/',
            'setQueueBookingPlanningTimeslots': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/queues/planning',
            'setQueueBookingExceptionsTimeslots': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/queues/exception',
            'delQueueBookingPlanningTimeslots': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/queues/planning',
            'delQueueBookingExceptionsTimeslots': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/queues/exception'
        },
        endpointsTimeslots: {
            'getEndpointsTimeslots': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/endpoints/',
            'getEndpointsRealTimeslots': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/endpoints/real',
            'setEndpointsPlanningTimeslots': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/endpoints/planning',
            'setEndpointsExceptionsTimeslots': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/endpoints/exception',
            'delEndpointsPlanningTimeslots': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/endpoints/planning',
            'delEndpointsExceptionsTimeslots': this.linebertyServer + this.appTimetable + 'api/api_admin/v1/endpoints/exception'
        },
        applications: {
            'getApplications': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/configurations/',
            'getVisualizationApplicationsByPlace': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/applications/places/{placeId}/visualization',
            // 'getVisualizationApplicationsByPlace': 'http://localhost:3047/api/api_admin/v1/applications/places/{placeId}/visualization',
            'getVisualizationApplicationsByQueue': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/applications/queues/{queueId}/visualization',
            // 'getVisualizationApplicationsByQueue': 'http://localhost:3047/api/api_admin/v1/applications/queues/{queueId}/visualization',
            'getApplicationsOfCompany': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/configurations/companies/{companyId}',
            'createApplications': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/configurations/',
            'createVisualizationApplication': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/applications',
            // 'createVisualizationApplication': 'http://localhost:3047/api/api_admin/v1/applications',
            'setApplications': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/configurations/',
            'setVisualizationApplication': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/applications',
            // 'setVisualizationApplication': 'http://localhost:3047/api/api_admin/v1/applications/',
            'deleteConfigurations': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/configurations/',
            'deleteApplications': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/applications/',
            'copyApplications': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/applications/byCopy',
        },
        interfaceErrors: {
            'getInterfaceErrors': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/interfaceErrors/',
            'getInterfaceErrorsByQueue': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/interfaceErrors/byQueueId',
            'getInterfaceErrorsByApplication': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/interfaceErrors/byApplicationId'
        },
        installations: {
            'getInstallations': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/configurations/{configurationId}/installations',
            'seleteInstallations': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/installations/',
            'deleteInstallations': this.linebertyServer + this.appDeviceManager + 'api/api_admin/v1/installations/'
        },
        endpoints: {
            'getEndpoints': this.linebertyServer + this.appEndpoint + 'api/api_admin/v1/endpoints/',
            'getEndpointsSSE': this.linebertyServer + this.appEndpoint + 'api/api_admin/v1/endpoints/sse',
            'createEndpoints': this.linebertyServer + this.appEndpoint + 'api/api_admin/v1/endpoints/',
            'setEndpoints': this.linebertyServer + this.appEndpoint + 'api/api_admin/v1/endpoints/',
            'deleteEndpoints': this.linebertyServer + this.appEndpoint + 'api/api_admin/v1/endpoints/',
            'getEndpointsOpeningHistory': this.linebertyServer + this.appEndpoint + 'api/api_admin/v1/endpoints/history/state',
            'getEndpointsConfigurations': this.linebertyServer + this.appEndpoint + 'api/api_admin/v1/endpoints/configurations/',
            'createEndpointsConfigurations': this.linebertyServer + this.appEndpoint + 'api/api_admin/v1/endpoints/configurations/',
            'setEndpointsConfigurations': this.linebertyServer + this.appEndpoint + 'api/api_admin/v1/endpoints/configurations/',
            'deleteEndpointsConfigurations': this.linebertyServer + this.appEndpoint + 'api/api_admin/v1/endpoints/configurations'
        },
        tickets: {
            'treatBookingDemands': this.linebertyServer + this.appBookingRequest + 'api/api_admin/v1/queues/{queueId}/treatBookingDemands',
            'createFakeTickets': this.linebertyServer + this.appBookingRequest + 'api/api_admin/v1/queues/{queueId}/bookingFakeTickets',
            'getAdminTicket': this.linebertyServer + this.appBooking + 'api/api_admin/v1/queues/tickets/{queueId}/ticket/{ticketId}',
            // 'getAdminTicket':  'http://localhost:3041/api/api_admin/v1/queues/tickets/{queueId}/ticket/{ticketId}',
            'setTicketsState':  this.linebertyServer + this.appBooking + 'api/api_admin/v1/queues/tickets/{queueId}/ticket/states',
            // 'setTicketsState':  'http://localhost:3041/api/api_admin/v1/queues/tickets/{queueId}/ticket/states',
            'getTicketSms': this.linebertyServer + this.appSmsPusher + 'api/api_admin/v1/'
        },
        developerAccounts: {
            'getDeveloperAccounts': this.linebertyServer + this.appAuth + 'api/api_admin/v1/developers/',
            'setDeveloperAccounts': this.linebertyServer + this.appAuth + 'api/api_admin/v1/developers/',
            'createDeveloperAccounts': this.linebertyServer + this.appAuth + 'api/api_admin/v1/developers/',
            'deleteDeveloperAccounts': this.linebertyServer + this.appAuth + 'api/api_admin/v1/developers/',
            'addQueuesToDeveloperAccounts': this.linebertyServer + this.appAuth + 'api/api_admin/v1/developers/addQueues',
            'deleteQueuesToDeveloperAccounts': this.linebertyServer + this.appAuth + 'api/api_admin/v1/developers/removeQueues',
            'getDevelopersAccountsFromResourcesId': this.linebertyServer + this.appAuth + 'api/api_admin/v1/developers/byResourcesId'
        },
        account: {
            'getOperatorRoles': this.linebertyServer + this.appAuth + 'api/api_admin/v1/auth/roles',
            'getOperatorAccount': this.linebertyServer + this.appAuth + 'api/api_admin/v1/auth/account',
            'createOperatorAccount': this.linebertyServer + this.appAuth + 'api/api_admin/v1/auth/account',
            'editOperatorAccount': this.linebertyServer + this.appAuth + 'api/api_admin/v1/auth/account',
            'editOperatorEmail': this.linebertyServer + this.appAuth + 'api/api_admin/v1/auth/email',
            'editOperatorPassword': this.linebertyServer + this.appAuth + 'api/api_admin/v1/auth/password',
            'deleteOperatorAccount': this.linebertyServer + this.appAuth + 'api/api_admin/v1/auth/account',
            'forgotPassword': this.linebertyServer + this.appAuth + 'api/api_admin/v1/auth/forgotPassword',
            'getAccountRules': this.linebertyServer + this.appAuth + 'api/api_admin/v1/accountRules/',
            'createAccountRule': this.linebertyServer + this.appAuth + 'api/api_admin/v1/accountRules/',
            'editAccountRule': this.linebertyServer + this.appAuth +  'api/api_admin/v1/accountRules/',
            'deleteAccountRule': this.linebertyServer + this.appAuth +  'api/api_admin/v1/accountRules/',

        },
        sessions: {
            'getSessions': this.linebertyServer + this.appAuth + 'api/api_admin/v1/sessions/',
        },
        sessionsLogs: {
            'getSessionsLogs': this.linebertyServer + this.appAuth + 'api/api_admin/v1/sessionlogs/',
        },
        operators: {
            'getAdminAccounts': this.linebertyServer + this.appAuth + 'api/api_admin/v1/operators/adminAccounts',
            'setAdminAccounts': this.linebertyServer + this.appAuth + 'api/api_admin/v1/operators/adminAccounts',
            'getOperators': this.linebertyServer + this.appAuth + 'api/api_admin/v1/operators/',
            'setOperators': this.linebertyServer + this.appAuth + 'api/api_admin/v1/operators/',
            'createOperators': this.linebertyServer + this.appAuth + 'api/api_admin/v1/operators/',
            'deleteOperators': this.linebertyServer + this.appAuth + 'api/api_admin/v1/operators/',
            'getOperatorsFromResourcesId': this.linebertyServer + this.appAuth + 'api/api_admin/v1/operators/byResourcesId',
        },
        users: {
            'getUsersFromResourcesId': this.linebertyServer + this.appAuth + 'api/api_admin/v1/users/byResourcesId',
        },
        profiles: {
            'getProfiles': this.linebertyServer + this.appAuth + 'api/api_admin/v1/profiles/',
            'setProfiles': this.linebertyServer + this.appAuth + 'api/api_admin/v1/profiles/',
            'createProfiles': this.linebertyServer + this.appAuth + 'api/api_admin/v1/profiles/',
            'deleteProfiles': this.linebertyServer + this.appAuth + 'api/api_admin/v1/profiles/'
        },
        snapshots: {
            'getSnapshots': this.linebertyServer + this.appBookingRequest + 'api/api_admin/v1/snapshots',
            'getSnapshot': this.linebertyServer + this.appBookingRequest + 'api/api_admin/v1/snapshots/{snapshotId}',
            'createSnapshots': this.linebertyServer + this.appBookingRequest + 'api/api_admin/v1/snapshots',
            'deleteSnapshots': this.linebertyServer + this.appBookingRequest + 'api/api_admin/v1/snapshots'
        },
        reportings: {
            'ticketCounters': this.linebertyServer + this.appReport + 'api/v1/reports/ticketsCounter',
            // 'oldReporting': 'http://localhost:3056/api/api_admin/v1/reports/oldReporting'
            'oldReporting': this.linebertyServer + this.appReport + 'api/api_admin/v1/reports/oldReporting'

        },
        sms: {
            'getMessageConfigurations': this.linebertyServer + this.appSmsPusher + 'api/api_admin/v1/messageConfigurations',
            'createMessageConfigurations': this.linebertyServer + this.appSmsPusher + 'api/api_admin/v1/messageConfigurations',
            'copyMessageConfigurations': this.linebertyServer + this.appSmsPusher + 'api/api_admin/v1/messageConfigurations/byCopy',
            'setMessageConfigurations': this.linebertyServer + this.appSmsPusher + 'api/api_admin/v1/messageConfigurations',
            'deleteMessageConfigurations': this.linebertyServer + this.appSmsPusher + 'api/api_admin/v1/messageConfigurations',
            'getQueueConfigurations': this.linebertyServer + this.appSmsPusher + 'api/api_admin/v1/queueConfigurations',
            'createQueueConfigurations': this.linebertyServer + this.appSmsPusher + 'api/api_admin/v1/queueConfigurations',
            'setQueueConfigurations': this.linebertyServer + this.appSmsPusher + 'api/api_admin/v1/queueConfigurations',
            'deleteQueueConfigurations': this.linebertyServer + this.appSmsPusher + 'api/api_admin/v1/queueConfigurations'
        },
        mail: {
            'getMessageConfigurations': this.linebertyServer + this.appMailPusher + 'api/api_admin/v1/messageConfigurations',
            'createMessageConfigurations': this.linebertyServer + this.appMailPusher + 'api/api_admin/v1/messageConfigurations',
            'copyMessageConfigurations': this.linebertyServer + this.appMailPusher + 'api/api_admin/v1/messageConfigurations/byCopy',
            'setMessageConfigurations': this.linebertyServer + this.appMailPusher + 'api/api_admin/v1/messageConfigurations',
            'deleteMessageConfigurations': this.linebertyServer + this.appMailPusher + 'api/api_admin/v1/messageConfigurations',
            'getQueueConfigurations': this.linebertyServer + this.appMailPusher + 'api/api_admin/v1/queueConfigurations',
            'createQueueConfigurations': this.linebertyServer + this.appMailPusher + 'api/api_admin/v1/queueConfigurations',
            'setQueueConfigurations': this.linebertyServer + this.appMailPusher + 'api/api_admin/v1/queueConfigurations',
            'deleteQueueConfigurations': this.linebertyServer + this.appMailPusher + 'api/api_admin/v1/queueConfigurations'
        },
        notification: {
            'getMessageConfigurations': this.linebertyServer + this.callExternalPusher + 'api/api_admin/v1/messageConfigurations',
            'createMessageConfigurations': this.linebertyServer + this.callExternalPusher + 'api/api_admin/v1/messageConfigurations',
            'copyMessageConfigurations': this.linebertyServer + this.callExternalPusher + 'api/api_admin/v1/messageConfigurations/byCopy',
            'setMessageConfigurations': this.linebertyServer + this.callExternalPusher + 'api/api_admin/v1/messageConfigurations',
            'deleteMessageConfigurations': this.linebertyServer + this.callExternalPusher + 'api/api_admin/v1/messageConfigurations',
            'getDeveloperConfigurations': this.linebertyServer + this.callExternalPusher + 'api/api_admin/v1/developerConfigurations',
            'createDeveloperConfigurations': this.linebertyServer + this.callExternalPusher + 'api/api_admin/v1/developerConfigurations',
            'setDeveloperConfigurations': this.linebertyServer + this.callExternalPusher + 'api/api_admin/v1/developerConfigurations',
            'deleteDeveloperConfigurations': this.linebertyServer + this.callExternalPusher + 'api/api_admin/v1/developerConfigurations'
        },
        extendedAttributesType: {
            'getGlobalExtendedAttributesType': this.linebertyServer + this.appBooking + 'api/api_admin/v1/extendedAttributes',
            'getCompanyExtendedAttributesType': this.linebertyServer + this.appBooking + 'api/api_admin/v1/extendedAttributes/companies/{companyId}',
            'createGlobalExtendedAttributesType': this.linebertyServer + this.appBooking + 'api/api_admin/v1/extendedAttributes',
            'createCompanyExtendedAttributesType': this.linebertyServer + this.appBooking + 'api/api_admin/v1/extendedAttributes/companies/{companyId}',
            'setGlobalExtendedAttributesType': this.linebertyServer + this.appBooking + 'api/api_admin/v1/extendedAttributes',
            'setCompanyExtendedAttributesType': this.linebertyServer + this.appBooking + 'api/api_admin/v1/extendedAttributes/companies/{companyId}',
            'deleteGlobalExtendedAttributesType': this.linebertyServer + this.appBooking + 'api/api_admin/v1/extendedAttributes',
            'deleteCompanyExtendedAttributesType': this.linebertyServer + this.appBooking + 'api/api_admin/v1/extendedAttributes/companies/{companyId}',
        },
        buckets: {
            'getFilesFromBucket': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/buckets/',
            'uploadFilesToBucket': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/buckets/',
            'deleteFilesFromBucket': this.linebertyServer + this.appAdmin + 'api/api_admin/v1/buckets/'
        },
        sso:{
            'getInformations': this.linebertyServer + this.appAuth + 'api/v2/sso/ssoInformation',
        }
    }

    public bucketsName = {
        'endpointDisplay': 'uat-display-images',
        'terminal': 'uat-terminal-images',
        'admin': 'static-admin-images-28c62c',
        'adminMail': 'static-mail-images-e33317'
    }

    public extensionTypesAllowed: any = {
        audio: ['mp3', 'flac', 'midi', 'mid', 'wav'],
        image: ['png', 'svg', 'jpg', 'jpeg', 'webp']
    }

    public mimeTypesAllowed: any = {
        audio: [
            'audio/x-wav',
            'audio/wav',
            'audio/mpeg'
        ],
        image: [
            'image/png',
            'image/svg+xml',
            'image/jpeg',
            'image/webp'
        ]
    }

    public linebertyDevelopersAccountId = {
        apps: '5c613d04a3ae2dc2aad2620b',
        webapps: '60094567e683be0ab62e3ede',
        terminal: '5e8aedb7642f54a26886f3a9',
        endpoint: '5e9996973a4f54a9c87aaf4a',
        display: '5fcf84930d46f8f98f85234c'
    }

    public linebertyDefaultProfilesId = {
        opsAdminVisual: '618959d4bcce42fead8a98ad',
        opsAdmin: '6189596243de2442b99dc9e8',
        dev: '60e6cc744218d4bc9dfbf5b5',
        devAdmin: '60e6e8104218d4bc9dfbf5b6',
        endpointUser: '607746414f5d824dfa496cec',
        endpointAttributes: '607746424f5d824dfa496ced',
        defaultAdmin: '60e6cc744218d4bc9dfbf5b5',
        defaultSupervisor: '',
        defaultManager: '',
        defaultAdminVisuals: '634ec1e850756a4ffe2cd645'
    }

    public linebertyDevelopersAccountEmail = {
        apps: 'lb-api-all@lineberty-sdk-1549875826.iam.gserviceaccount.com',
        webapps: 'lb-api-service@webapps-uat-1611219984.iam.gserviceaccount.com',
        terminal: 'lb-api-service@terminal-uat-1586161676.iam.gserviceaccount.com',
        endpoint: 'lb-api-service@endpoint-uat-1587122364.iam.gserviceaccount.com',
        display: 'lb-api-service@display-uat-1607434818.iam.gserviceaccount.com'
    }

    public ticketFields: {[value: string]: Langs} = {
        'appointmentType.name': { 'fr_FR': 'Motif de Visite' },
        'display.name': { 'fr_FR': 'Nom du Client' },
        'display.ticketType':  { 'fr_FR': 'Type de Ticket' },
        'extendedAttributes.phone': { 'fr_FR': 'N°Téléphone' },
        'extendedAttributes.mail': { 'fr_FR': 'E-mail' },
    }

    public indicatorsList: {name: string, title: Langs, type: 'sum' | 'appointmentTypes'}[] = [
        {name: 'remainingTickets', title: {fr_FR: 'Nb Tickets à valider', en_GB: 'Number of tickets to validate', en_US: 'Number of tickets to validate'}, type: 'sum'},
        {name: 'remainingPeople', title: {fr_FR: 'Nb personnes à passer', en_GB: 'Number of people waiting', en_US: 'Number of people waiting'}, type: 'sum'},
        {name: 'validatedTickets', title: {fr_FR: 'Nb Tickets validés', en_GB: 'Number of validated tickets', en_US: 'Number of validated tickets'}, type: 'sum'},
        {name: 'validatedPeople', title: {fr_FR: 'Nb personnes validées', en_GB: 'Number of people validated', en_US: 'Number of people validated'}, type: 'sum'},
        {name: 'estimatedWaitingTime', title: {fr_FR: 'Temps d’attente estimé', en_GB: 'Estimated waiting time', en_US: 'Estimated waiting time'}, type: 'sum'}
    ]

    public textToSpeechVoices: any = {
        azure: {
            'da_DK': 'da-DK-HelleRUS',
            'de_DE': 'de-DE-KatjaNeural',
            'en_AU': 'en-AU-Catherine',
            'en_CA': 'en-CA-Linda',
            'en_GB': 'en-GB-Susan-Apollo',
            'en_IE': 'en-IE-Sean',
            'en_IN': 'en-IN-Heera-Apollo',
            'en_US': 'en-US-JessaNeural',
            'es_ES': 'es-ES-Laura-Apollo',
            'es_MX': 'es-MX-HildaRUS',
            'fi_FI': 'fi-FI-HeidiRUS',
            'fr_CA': 'fr-CA-Caroline',
            'fr_FR': 'fr-FR-Julie-Apollo',
            'he-IL': 'he-IL-Asaf',
            'hi_IN': 'hi-IN-Kalpana-Apollo',
            'hr_HR': 'hr-HR-Matej ',
            'hu_HU': 'hu-HU-Szabolcs',
            'id_ID': 'id-ID-Andika',
            'it_IT': 'it-IT-ElsaNeural',
            'ja_JP': 'ja-JP-Ayumi-Apollo',
            'ko_KR': 'ko-KR-HeamiRUS',
            'ms_MY': 'ms-MY-Rizwan',
            'nb_NO': 'nb-NO-HuldaRUS',
            'nl_NL': 'nl-NL-HannaRUS',
            'pl_PL': 'pl-PL-PaulinaRUS',
            'pt_BR': 'pt-BR-HeloisaRUS',
            'pt_PT': 'pt-PT-HeliaRUS',
            'ro_RO': 'ro-RO-Andrei ',
            'ru_RU': 'ru-RU-Irina-Apollo',
            'sk_SK': 'sk-SK-Filip',
            'sl_SI': 'sl-SI-Lado',
            'sv_SE': 'sv-SE-HedvigRUS',
            'ta_IN': 'ta-IN-Valluvar',
            'te_IN': 'te-IN-Chitra',
            'th_TH': 'th-TH-Pattara',
            'tr_TR': 'tr-TR-SedaRUS',
            'vi_VN': 'vi-VN-An',
            'zh_CN': 'zh-CN-XiaoxiaoNeural',
            'zh_HK': 'zh-HK-Tracy-Apollo',
            'zh_TW': 'zh-TW-Yating-Apollo',
        },
        google: {
            'da_dk': { name: 'da-DK-Wavenet-A', gender: 'FEMALE' },
            'de_DE': { name: 'de-DE-Wavenet-A', gender: 'FEMALE' },
            'en_AU': { name: 'en-AU-Wavenet-E', gender: 'FEMALE' },
            'en_GB': { name: 'en-GB-Wavenet-E', gender: 'FEMALE' },
            'en_US': { name: 'en-US-Wavenet-E', gender: 'FEMALE' },
            'es_ES': { name: 'es-ES-Standard-A', gender: 'FEMALE' },
            'fr_FR': { name: 'fr-FR-Wavenet-A', gender: 'FEMALE' },
            'fr_CA': { name: 'fr-CA-Wavenet-A', gender: 'FEMALE' },
            'it_IT': { name: 'it-IT-Wavenet-A', gender: 'FEMALE' },
            'ja_JP': { name: 'ja-JP-Wavenet-A', gender: 'FEMALE' },
            'ko_KR': { name: 'ko-KR-Wavenet-A', gender: 'FEMALE' },
            'nb_NO': { name: 'nb-NO-Wavenet-E', gender: 'FEMALE' },
            'nl_NL': { name: 'nl-NL-Wavenet-E', gender: 'FEMALE' },
            'pl_PL': { name: 'pl-PL-Wavenet-A', gender: 'FEMALE' },
            'pt_BR': { name: 'pt-BR-Wavenet-A', gender: 'FEMALE' },
            'pt_PT': { name: 'pt-PT-Wavenet-A', gender: 'FEMALE' },
            'ru_RU': { name: 'ru-RU-Wavenet-A', gender: 'FEMALE' },
            'sk_SK': { name: 'sk-SK-Wavenet-A', gender: 'FEMALE' },
            'sv_SE': { name: 'sv-SE-Wavenet-A', gender: 'FEMALE' },
            'tr_TR': { name: 'tr-TR-Wavenet-A', gender: 'FEMALE' },
            'uk_UA': { name: 'uk-UA-Wavenet-A', gender: 'FEMALE' },
        }
    }

}