export const environment = {
    production: false,

    googleProjectId: 'lineberty-uat-3db57c',
    linebertyAdminUrl: 'https://admin2.winter.lineberty.net/',
    linebertyBookingWebappUrl: 'https://uat-booking.lineberty.net/',
    linebertyValidationWebappUrl: 'https://uat-validation.lineberty.net/',
    linebertyDisplayWebappUrl: 'https://uat-display.lineberty.net/',
    linebertyBookingApi: 'https://api-booking.winter.lineberty.net/',
    linebertyValidationApi: 'https://api-validation.winter.lineberty.net/',
    linebertyDisplayApi: 'https://api-display.winter.lineberty.net/',
    linebertyMobileWebAppUrl: 'https://webapp.winter.lineberty.net/',

    linebertyServer: 'https://gw.winter.lineberty.net/',

    availableLinebertyWebappSubDomain: [
        {
            type: 'booking',
            subDomain: 'uat-booking.lineberty.net',
            method: 'https'
        },
        {
            type: 'display',
            subDomain: 'uat-display.lineberty.net',
            method: 'https'
        },
        {
            type: 'validation',
            subDomain: 'uat-validation.lineberty.net',
            method: 'https'
        },
        {
            type: 'validation',
            subDomain: 'uat-sso-validation.lineberty.net',
            method: 'https'
        },
    ],

    linebertyDevelopersAccountId: {
        apps: '5c613d04a3ae2dc2aad2620b',
        webapps: '60094567e683be0ab62e3ede',
        terminal: '5e8aedb7642f54a26886f3a9',
        endpoint: '5e9996973a4f54a9c87aaf4a',
        display: '5fcf84930d46f8f98f85234c'
    },

    linebertyDefaultProfilesId: {
        opsAdminVisual: '618959d4bcce42fead8a98ad',
        opsAdmin: '6189596243de2442b99dc9e8',
        devAdminVisual: '60e6cc744218d4bc9dfbf5b5',
        devAdmin: '60e6e8104218d4bc9dfbf5b6',
        endpointUser: '607746414f5d824dfa496cec',
        endpointAttributes: '607746424f5d824dfa496ced',
        defaultAdmin: '60e6cc744218d4bc9dfbf5b5',
        defaultSupervisor: '',
        defaultManager: '',
        defaultAdminVisuals: '634ec1e850756a4ffe2cd645'
    },

    linebertyDevelopersAccountEmail: {
        apps: 'lb-api-all@lineberty-sdk-1549875826.iam.gserviceaccount.com',
        webapps: 'lb-api-service@webapps-uat-1611219984.iam.gserviceaccount.com',
        terminal: 'lb-api-service@terminal-uat-1586161676.iam.gserviceaccount.com',
        endpoint: 'lb-api-service@endpoint-uat-1587122364.iam.gserviceaccount.com',
        display: 'lb-api-service@display-uat-1607434818.iam.gserviceaccount.com'
    },

    defaultValidationThemeMobileOperatorValidationProfiles: [ '607746414f5d824dfa496cec', '607746424f5d824dfa496ced' ],

    googleMapApiKey: 'AIzaSyBAQlxq1VXX2g7vB1wj7ezwpxInMl-7UTs'
}
