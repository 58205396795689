import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy } from '@nebular/auth'
import { NbRoleProvider, NbSecurityModule } from '@nebular/security'
import { of as observableOf } from 'rxjs'

import { throwIfAlreadyLoaded } from './module-import-guard'
import { LayoutService, PlayerService, SeoService, StateService } from './utils'
import { ElectricityData } from './data/electricity'
import { UserActivityData } from './data/user-activity'
import { OrdersChartData } from './data/orders-chart'
import { ProfitChartData } from './data/profit-chart'
import { TrafficListData } from './data/traffic-list'
import { EarningData } from './data/earning'
import { OrdersProfitChartData } from './data/orders-profit-chart'
import { TrafficBarData } from './data/traffic-bar'
import { ProfitBarAnimationChartData } from './data/profit-bar-animation-chart'
import { TemperatureHumidityData } from './data/temperature-humidity'
import { SolarData } from './data/solar'
import { TrafficChartData } from './data/traffic-chart'
import { StatsBarData } from './data/stats-bar'
import { CountryOrderData } from './data/country-order'
import { StatsProgressBarData } from './data/stats-progress-bar'
import { SecurityCamerasData } from './data/security-cameras'

import { ElectricityService } from './mock/electricity.service'
import { UserActivityService } from './mock/user-activity.service'
import { OrdersChartService } from './mock/orders-chart.service'
import { ProfitChartService } from './mock/profit-chart.service'
import { TrafficListService } from './mock/traffic-list.service'
import { EarningService } from './mock/earning.service'
import { OrdersProfitChartService } from './mock/orders-profit-chart.service'
import { TrafficBarService } from './mock/traffic-bar.service'
import { ProfitBarAnimationChartService } from './mock/profit-bar-animation-chart.service'
import { TemperatureHumidityService } from './mock/temperature-humidity.service'
import { SolarService } from './mock/solar.service'
import { TrafficChartService } from './mock/traffic-chart.service'
import { StatsBarService } from './mock/stats-bar.service'
import { CountryOrderService } from './mock/country-order.service'
import { StatsProgressBarService } from './mock/stats-progress-bar.service'
import { SecurityCamerasService } from './mock/security-cameras.service'
import { MockDataModule } from './mock/mock-data.module'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { AuthInterceptor } from '../interceptors/auth.interceptor'

const DATA_SERVICES = [
    { provide: ElectricityData, useClass: ElectricityService },
    { provide: UserActivityData, useClass: UserActivityService },
    { provide: OrdersChartData, useClass: OrdersChartService },
    { provide: ProfitChartData, useClass: ProfitChartService },
    { provide: TrafficListData, useClass: TrafficListService },
    { provide: EarningData, useClass: EarningService },
    { provide: OrdersProfitChartData, useClass: OrdersProfitChartService },
    { provide: TrafficBarData, useClass: TrafficBarService },
    { provide: ProfitBarAnimationChartData, useClass: ProfitBarAnimationChartService },
    { provide: TemperatureHumidityData, useClass: TemperatureHumidityService },
    { provide: SolarData, useClass: SolarService },
    { provide: TrafficChartData, useClass: TrafficChartService },
    { provide: StatsBarData, useClass: StatsBarService },
    { provide: CountryOrderData, useClass: CountryOrderService },
    { provide: StatsProgressBarData, useClass: StatsProgressBarService },
    { provide: SecurityCamerasData, useClass: SecurityCamerasService }
]

export class NbSimpleRoleProvider extends NbRoleProvider {
    getRole () {
        // here you could provide any role based on any auth flow
        return observableOf( 'guest' )
    }
}

export const NB_CORE_PROVIDERS = [
    ...MockDataModule.forRoot().providers,
    ...DATA_SERVICES,
    ...NbAuthModule.forRoot( {

        strategies: [
            NbPasswordAuthStrategy.setup( {
                name: 'loginId',

                baseEndpoint: 'https://gw.winter.lineberty.net/appauth',
                register: false,

                login: {
                    endpoint: '/api/api_admin/v1/auth/login',
                    method: 'post',
                    redirect: {
                        success: '/auth/after-login',
                        failure: null
                    },
                    defaultErrors: ['Identifiant/Mot de passe incorrecte, veuillez réessayer.'],
                    defaultMessages: ['Vous avez été correctement connecté.']
                },
                logout: {
                    endpoint: '/api/api_admin/v1/auth/logout',
                    method: 'delete',
                    redirect: {
                        success: '/auth/sso-login',
                        failure: null
                    }
                },
                resetPass: {
                    endpoint: '/api/api_admin/v1/auth/resetPassword',
                    method: 'post',
                    redirect: {
                        success: '/auth/login',
                        failure: null
                    }
                },
                requestPass: {
                    endpoint: '/api/api_admin/v1/auth/forgotPassword',
                    method: 'post',
                    redirect: {
                        success: null,
                        failure: null
                    }
                },
                refreshToken: {
                    endpoint: '/api/api_admin/v1/auth/refreshToken',
                    method: 'get'
                },
                token: {
                    class: NbAuthJWTToken,
                    key: 'jwtToken'
                }
            } )
        ],
        forms: {
            login: {
                // delay before redirect after a successful login, while success message is shown to the user
                redirectDelay: 10,
                strategy: 'loginId',  // strategy id key.
                rememberMe: false,   // whether to show or not the `rememberMe` checkbox
                showMessages: {     // show/not show success/error messages
                    success: false,
                    error: true
                }
            },
            resetPassword: {
                redirectDelay: 500,
                strategy: 'loginId',
                showMessages: {
                    success: true,
                    error: true
                }
            },
            requestPassword: {
                redirectDelay: 500,
                strategy: 'loginId',
                showMessages: {
                    success: true,
                    error: true
                }
            },
            logout: {
                redirectDelay: 50,
                strategy: 'loginId'
            },
            validation: {
                password: {
                    required: true,
                    minLength: 4,
                    maxLength: 50
                },
                email: {
                    required: true
                }
            }
        }
    } ).providers,

    NbSecurityModule.forRoot( {
        accessControl: {
            guest: {
                view: '*'
            },
            user: {
                parent: 'guest',
                create: '*',
                edit: '*',
                remove: '*'
            }
        }
    } ).providers,

    {
        provide: NbRoleProvider, useClass: NbSimpleRoleProvider
    },
    {
        provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
    },
    LayoutService,
    PlayerService,
    SeoService,
    StateService
]

@NgModule( {
    imports: [
        CommonModule
    ],
    exports: [
        NbAuthModule
    ],
    declarations: []
} )
export class CoreModule {
    constructor ( @Optional() @SkipSelf() parentModule: CoreModule ) {
        throwIfAlreadyLoaded( parentModule, 'CoreModule' )
    }

    static forRoot (): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                ...NB_CORE_PROVIDERS
            ]
        }
    }
}
