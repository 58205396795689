import { Injectable } from '@angular/core'
import { forkJoin, Observable, of } from 'rxjs'
import { first, switchMap } from 'rxjs/operators'
import * as _ from 'lodash'
import { LbUtilsService } from 'lb-utils-front/dist'
import { HttpService } from '../utils/http.service'
import { ConfigService } from '../config.service'
import { Developer, DeveloperAccessCompany, Langs } from 'lb-types/dist'
import { HttpCRUDRes, LocalCRUDRes } from '../../interfaces/http/http'
import { LoadingService } from '../utils/loading.service'
import { CacheService } from '../utils/cache.service'

export type linebertyDeveloperAccountType = 'terminal' | 'endpoint' | 'display' | 'app' | 'webapp'
export interface ResourceId {
    companiesId?: string[],
    placesId?: string[],
    queuesId?: string[],
}

@Injectable( {
    providedIn: 'root'
} )
export class DeveloperAccountService {

    private developerAccountsLoaded: boolean = false
    private developerAccounts: { [developerId: string]: Developer } = {}

    constructor (
        private lbUtilsService: LbUtilsService,
        private cacheService: CacheService,
        private httpService: HttpService,
        private loadingService: LoadingService,
        private configService: ConfigService
    ) {
    }

    public getDeveloperAccounts ( force: boolean = false ): Observable<{ [developerId: string]: Developer }> {
        if ( this.developerAccountsLoaded && !force ) {
            return of( _.cloneDeep(this.developerAccounts) )
        }
        else {
            return this.httpService.get(
                this.configService.httpUrl.developerAccounts.getDeveloperAccounts, null, null
            ).pipe(
                switchMap( ( res: { [developerId: string]: Developer } ) => {
                    this.developerAccountsLoaded = true
                    this.developerAccounts = res
                    return of( _.cloneDeep( this.developerAccounts ) )
                } ),
                first()
            )
        }
    }

    public getDeveloperAccountsList( excludeDeveloperId?: string[] ): {developerId: string, langs: Langs}[] {
        const res = []
        for ( const developerId in this.developerAccounts ) {
            if ( !excludeDeveloperId || excludeDeveloperId.indexOf( developerId ) < 0 ) {
                res.push( {
                    developerId: developerId,
                    langs: this.developerAccounts[ developerId ].infos.name
                })
            }
        }
        return res
    }

    public getDeveloperById ( id: string ): Developer | null {
        if ( id && this.developerAccounts && this.developerAccounts[ id ] ) {
            return this.developerAccounts[ id ]
        } else {
            return null
        }
    }

    /**
     * 
     * @param resourcesId 
     * @returns 
     */
    public getDevelopersFromResourcesId(resourcesId: ResourceId): Observable<any> {
        return this.httpService.get(
            this.configService.httpUrl.developerAccounts.getDevelopersAccountsFromResourcesId, resourcesId, null
        ).pipe(
            switchMap((res: { [queueId: string]: any }) => {
                return of(res)
            })
        )
    }

    public createDevelopers(developers: Partial<Developer>[]): Observable<LocalCRUDRes> {
        return this.httpService.post(
            this.configService.httpUrl.developerAccounts.createDeveloperAccounts, { developers: developers }, null, null
        ).pipe(
            switchMap((httpRes: HttpCRUDRes) => {
                if (httpRes.error === 0 && httpRes.success > 0) {
                    for (const developerId in httpRes.objectSuccess) {
                        this.developerAccounts[developerId] = httpRes.objectSuccess[developerId]
                    }
                    return of({ success: true, res: httpRes.objectSuccess })
                } else {
                    return of({ success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success), errors: httpRes.objectError })
                }
            })
        )
    }

    public searchById ( id: string ): Observable<{ found: boolean, type: string, res: { developerAccount: Developer | null } }> {
        if ( id ) {
            return this.getDeveloperAccounts().pipe(
                switchMap( ( developerAccounts: { [developerId: string]: Developer } ) => {
                    const developerAccount = developerAccounts[id]
                    if ( developerAccount ) {
                        return of( { found: true, type: 'developerAccount', res: { developerAccount: _.cloneDeep( developerAccount ) } } )
                    }
                    else {
                        return of( { found: false, type: '', res: { developerAccount: null } } )
                    }
                } ),
                first()
            )
        }
        else {
            return of( { found: false, type: '', res: { developerAccount: null } } )
        }
    }

    public deleteDevelopers ( params: string[] ): Observable<LocalCRUDRes> {

        return this.httpService.delete(
            this.configService.httpUrl.developerAccounts.deleteDeveloperAccounts,
            { developersIds: params, field: '_id' }, null, null
        ).pipe(
            switchMap( ( httpRes: HttpCRUDRes ) => {
                for ( const developerId in httpRes.objectSuccess ) {
                    if ( developerId in httpRes.objectSuccess ) {
                        delete this.developerAccounts[developerId]
                    }
                }

                if ( httpRes.error === 0 && httpRes.success > 0 ) {
                    return of( { success: true } )
                }
                else {
                    return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success), errors: httpRes.objectError } )
                }
            } )
        )
    }

    public editDevelopers ( params: { developerId: string, developerSet: any }[] ): Observable<LocalCRUDRes> {

        const developers = params.map( obj => this.searchById( obj.developerId ) )

        return forkJoin( ...developers ).pipe(
            switchMap( ( foundResTab: { found: boolean, type: string, res: { developerAccount: Developer | null } }[] ) => {
                const set = {}

                for ( const k in foundResTab ) {
                    if ( k in foundResTab ) {
                        if ( foundResTab[k].found ) {
                            const developer = foundResTab[k].res.developerAccount
                            const tmpDeveloperSet = params.find( ( obj ) => {
                                return obj.developerId === developer.developerId
                            } )
                            const developerSet: any = this.lbUtilsService.diffObject( tmpDeveloperSet.developerSet, developer )

                            if ( developerSet && Object.keys( developerSet ).length > 0 ) {

                                if ( typeof (developerSet.infos) !== 'undefined' ) {
                                    if ( typeof (developerSet.infos.name) !== 'undefined' ) {
                                        developerSet.infos.name = _.cloneDeep( tmpDeveloperSet.developerSet.infos.name )
                                    }
                                }

                                if ( typeof (developerSet.config) !== 'undefined' ) {
                                    if ( typeof (developerSet.config.token) !== 'undefined' ) {
                                        if ( typeof (developerSet.config.token.duration) !== 'undefined' ) {
                                            developerSet.config.token.duration = _.cloneDeep( tmpDeveloperSet.developerSet.config.token.duration )
                                        }
                                        if ( typeof (developerSet.config.token.refreshAllowed) !== 'undefined' ) {
                                            developerSet.config.token.refreshAllowed = _.cloneDeep( tmpDeveloperSet.developerSet.config.token.refreshAllowed )
                                        }
                                    }
                                }

                                if ( typeof (developerSet.accesses) !== 'undefined' ) {
                                    if ( typeof (developerSet.accesses.takeTickets) !== 'undefined' ) {
                                        developerSet.accesses.takeTickets = _.cloneDeep( tmpDeveloperSet.developerSet.accesses.takeTickets )
                                    }
                                    if ( typeof (developerSet.accesses.validateTickets) !== 'undefined' ) {
                                        developerSet.accesses.validateTickets = _.cloneDeep( tmpDeveloperSet.developerSet.accesses.validateTickets )
                                    }
                                    if ( typeof (developerSet.accesses.displayTickets) !== 'undefined' ) {
                                        developerSet.accesses.displayTickets = _.cloneDeep( tmpDeveloperSet.developerSet.accesses.displayTickets )
                                    }
                                    if ( typeof (developerSet.accesses.operators) !== 'undefined' ) {
                                        developerSet.accesses.operators = _.cloneDeep( tmpDeveloperSet.developerSet.accesses.operators )
                                    }
                                }

                                if ( typeof (developerSet.webServices) !== 'undefined' ) {
                                    if ( typeof (developerSet.webServices.ssoLogin) !== 'undefined' ) {
                                        developerSet.webServices.ssoLogin = _.cloneDeep( tmpDeveloperSet.developerSet.webServices.ssoLogin )
                                    }
                                    if ( typeof (developerSet.webServices.notification) !== 'undefined' ) {
                                        developerSet.webServices.notification = _.cloneDeep( tmpDeveloperSet.developerSet.webServices.notification )
                                    }
                                }

                            }

                            set[tmpDeveloperSet.developerId] = developerSet
                        }
                    }
                }

                return of( set )
            } ),
            switchMap( ( setParam: any ) => {
                return this.httpService.put(
                    this.configService.httpUrl.developerAccounts.setDeveloperAccounts,
                    { developers: setParam }, null, null
                )
            }),
            switchMap( ( httpRes: HttpCRUDRes ) => {
                const cacheKeys = []
                for ( const developerId in httpRes.objectSuccess ) {
                    if ( developerId in httpRes.objectSuccess ) {
                        cacheKeys.push('/developer/' + developerId)
                        if ( httpRes.objectSuccess[developerId].apiKey ) {
                            if ( httpRes.objectSuccess[developerId].apiKey.takeTickets && httpRes.objectSuccess[developerId].apiKey.takeTickets.length > 0 ) {
                                cacheKeys.push('/apiKey/' + httpRes.objectSuccess[developerId].apiKey.takeTickets)
                            }
                            if ( httpRes.objectSuccess[developerId].apiKey.validateTickets && httpRes.objectSuccess[developerId].apiKey.validateTickets.length > 0 ) {
                                cacheKeys.push('/apiKey/' + httpRes.objectSuccess[developerId].apiKey.validateTickets)
                            }
                            if ( httpRes.objectSuccess[developerId].apiKey.displayTickets && httpRes.objectSuccess[developerId].apiKey.displayTickets.length > 0 ) {
                                cacheKeys.push('/apiKey/' + httpRes.objectSuccess[developerId].apiKey.displayTickets)
                            }
                        }
                    }
                }

                return this.cacheService.deleteKeys( cacheKeys, true ).pipe(
                    switchMap( () => {
                        console.log('Operator rôles cache key deleted')
                        return of( httpRes )
                    })
                )
            }),
            switchMap( ( httpRes: HttpCRUDRes ) => {
                for ( const developerId in httpRes.objectSuccess ) {
                    if ( developerId in httpRes.objectSuccess ) {
                        this.developerAccounts[developerId] = httpRes.objectSuccess[developerId]
                    }
                }

                if ( httpRes.error === 0 && httpRes.success > 0 ) {
                    return of( { success: true } )
                }
                else {
                    return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                }
            })
        )
    }

    public deleteDevelopersCacheKeys( developersId: string[] ) : Observable<HttpCRUDRes> {
        const cacheKeys = []
        for ( const developerId of developersId ) {
            cacheKeys.push('/developer/' + developerId)
            const developer: Developer = this.getDeveloperById( developerId )
            if ( developer && developer.apiKey ) {
                if ( developer.apiKey.takeTickets && developer.apiKey.takeTickets.length > 0 ) {
                    cacheKeys.push('/apiKey/' + developer.apiKey.takeTickets)
                }
                if ( developer.apiKey.validateTickets && developer.apiKey.validateTickets.length > 0 ) {
                    cacheKeys.push('/apiKey/' + developer.apiKey.validateTickets)
                }
                if ( developer.apiKey.displayTickets && developer.apiKey.displayTickets.length > 0 ) {
                    cacheKeys.push('/apiKey/' + developer.apiKey.displayTickets)
                }
            }
        }

        return this.cacheService.deleteKeys( cacheKeys, true )
    }

    /**
     * Get the list of developerId that use a some profiles (at least one of a list)
     * @param profilesIds - the list of profile ID
     */
    public getDevelopersIdIdOfProfiles( profilesIds: string[] ): Observable<string[]> {
        const developerIds: string[] = []
        return this.getDeveloperAccounts().pipe(
            switchMap( ( developers: { [developerId: string]: Developer } ): Observable<string[]> => {
                for ( const developerId in developers ) {
                    if (
                        developers[developerId].accesses
                        && developers[developerId].accesses.operators
                        && developers[developerId].accesses.operators.defaultProfiles
                        && ((developers[developerId].accesses.operators.defaultProfiles.all && developers[developerId].accesses.operators.defaultProfiles.all.length > 0) || (developers[developerId].accesses.operators.defaultProfiles.byCompanies && developers[developerId].accesses.operators.defaultProfiles.byCompanies.length > 0))
                    ) {
                        const profiles = developers[developerId].accesses.operators.defaultProfiles
                        for ( const profileId of profiles.all ) {
                            if ( profilesIds.indexOf( profileId ) >= 0 && developerIds.indexOf( developerId ) < 0 ) {
                                developerIds.push( developerId )
                                break
                            }
                        }
                        if ( profiles.byCompanies && profiles.byCompanies.length > 0 ) {
                            for ( const companyData of profiles.byCompanies ) {
                                if ( companyData.all && companyData.all.length > 0 ) {
                                    for ( const profileId of companyData.all ) {
                                        if ( profilesIds.indexOf( profileId ) >= 0 && developerIds.indexOf( developerId ) < 0 ) {
                                            developerIds.push( developerId )
                                            break
                                        }
                                    }
                                }
                                if ( companyData.byPlaces && companyData.byPlaces.length > 0 ) {
                                    for ( const placeData of companyData.byPlaces ) {
                                        if ( placeData.all && placeData.all.length > 0 ) {
                                            for ( const profileId of placeData.all ) {
                                                if ( profilesIds.indexOf( profileId ) >= 0 && developerIds.indexOf( developerId ) < 0 ) {
                                                    developerIds.push( developerId )
                                                    break
                                                }
                                            }
                                        }
                                        if ( placeData.byQueues && placeData.byQueues.length > 0 ) {
                                            for ( const queueData of placeData.byQueues ) {
                                                for ( const profileId of queueData.profiles ) {
                                                    if ( profilesIds.indexOf( profileId ) >= 0 && developerIds.indexOf( developerId ) < 0 ) {
                                                        developerIds.push( developerId )
                                                        break
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                return of( developerIds )
            })
        )
    }

    public checkPlacesExistInLinebertyDeveloperAccount(types: linebertyDeveloperAccountType[], companyId: string, placesId: string[]): Observable<{ [placeId: string]: { [type in linebertyDeveloperAccountType]?: boolean } }> {
        return forkJoin(
            Object.assign({}, ...placesId.map((placeId) => {
                return {
                    [placeId]: this.checkQueueExistInLinebertyDeveloperAccount(types, companyId, placeId)
                }
            }))
        ) as any as Observable<{ [placeId: string]: { [type in linebertyDeveloperAccountType]?: boolean } }>
    }

    /**
     * Need to known if a company/place/queue exist in the lineberty accounts
     * @param types - the list of lineberty account in which we must add the queue/place/company
     * @param companyId - The ID of the company to add
     * @param placeId - the ID of the place to add
     * @param queueId - the ID of the queue to add (optinal, if not filled, check if place exist)
     */
    public checkQueueExistInLinebertyDeveloperAccount ( types: linebertyDeveloperAccountType[], companyId: string, placeId: string, queueId?: string ): Observable<{ [ type in linebertyDeveloperAccountType ]?: boolean }> {
        const res: { [ type in linebertyDeveloperAccountType ]?: boolean } = {}
        for ( const type of types ) {
            res[ type ] = false
        }

        const url = this.configService.httpUrl.developerAccounts.checkQueuesInDeveloperAccounts
        const body = {
            types: types,
            companyPlaceQueue: {
                companyId: companyId,
                placeId: placeId,
                queueId: queueId
            }
        }

        return this.httpService.post(url, body, null, null).pipe(
            switchMap( (httpRes:  {[type in linebertyDeveloperAccountType ]?: boolean}) => {
                for (const type of types) {
                    res[type] = httpRes[type]
                }
                return of(res)
            })
        )
    }

    /**
     * Add a company and/or place and/or queue to the lineberty developper accounts
     * @param action - the action to do on the developpers account (add or remove) the queue from the access
     * @param types - the list of lineberty account in which we must add the queue/place/company
     * @param companiesPlacesQueues - an array of data that contains : companyId - The ID of the company to add ; placeId - the ID of the place to add ; queueId - the ID of the queue to add
     */
    public addOrRemoveQueueToLinebertyDeveloperAccount ( action: 'add' | 'remove', types: linebertyDeveloperAccountType[], companiesPlacesQueues: {companyId: string, placeId: string, queueId?: string}[] ): Observable<boolean> {
        const ids = this.configService.linebertyDevelopersAccountId

        const devsAccountIds = []
        if (  types.indexOf('app') >= 0 && ids.apps ) { devsAccountIds.push( {id: ids.apps, type: ['booking'] } ) }
        if (  types.indexOf('webapp') >= 0 && ids.webapps ) { devsAccountIds.push( {id: ids.webapps, type: ['booking']} ) }
        if (  types.indexOf('terminal') >= 0 && ids.terminal ) { devsAccountIds.push( {id: ids.terminal, type: ['booking']} ) }
        if (  types.indexOf('endpoint') >= 0 && ids.endpoint ) { devsAccountIds.push( {id: ids.endpoint, type: ['booking', 'validation']} ) }
        if (  types.indexOf('display') >= 0 && ids.display ) { devsAccountIds.push( {id: ids.display, type: ['display']} ) }

        const edit = {}
        for ( const data of devsAccountIds ) {
            edit[ data.id ] = { 'booking': [], 'validation': [], 'display': [] }
            for ( const companiesPlacesQueue of companiesPlacesQueues ) {
                if ( data.type.indexOf('booking') >= 0 ) { edit[ data.id ].booking.push( companiesPlacesQueue ) }
                if ( data.type.indexOf('validation') >= 0 ) { edit[ data.id ].validation.push( companiesPlacesQueue ) }
                if ( data.type.indexOf('display') >= 0 ) { edit[ data.id ].display.push( companiesPlacesQueue ) }
            }
        }

        let url = this.configService.httpUrl.developerAccounts.addQueuesToDeveloperAccounts
        let reqAction = 'put'
        if ( action === 'remove' ) {
            url = this.configService.httpUrl.developerAccounts.deleteQueuesToDeveloperAccounts
            reqAction = 'delete'
        }

        let res
        return this.httpService[reqAction](url , {developers: edit}, null, null ).pipe(
            switchMap( ( httpRes: any ) => {
                const cacheKeys = []
                for ( const data of devsAccountIds ) {
                    const developer = this.getDeveloperById( data.id )
                    if ( developer && developer.apiKey ) {
                        if ( developer.apiKey.takeTickets && developer.apiKey.takeTickets.length > 0 ) {
                            cacheKeys.push('/apiKey/' + developer.apiKey.takeTickets)
                        }
                        if ( developer.apiKey.validateTickets && developer.apiKey.validateTickets.length > 0 ) {
                            cacheKeys.push('/apiKey/' + developer.apiKey.validateTickets)
                        }
                        if ( developer.apiKey.displayTickets && developer.apiKey.displayTickets.length > 0 ) {
                            cacheKeys.push('/apiKey/' + developer.apiKey.displayTickets)
                        }
                    }
                    cacheKeys.push( '*appAdmin/developer/' + data.id + '/*/accessCodes*' )
                }
                res = httpRes.res
                return this.cacheService.deleteKeys( cacheKeys, true )
            }),
            switchMap( () => {
                return this.getDeveloperAccounts( true )
            }),
            switchMap( () => {
                if ( res ) {
                    return of( true)
                } else {
                    return of( false )
                }
            })
        )

        /*return this.getDeveloperAccounts().pipe(
            switchMap( ( developers: { [developerId: string]: Developer } ) => {
                const editedDevelopersAccount: { developerId: string, developerSet: any }[] = []
                const developersData = {}
                for ( const k in companiesPlacesQueues ) {
                    const companyId = companiesPlacesQueues[k].companyId
                    const placeId = companiesPlacesQueues[k].placeId
                    const queueId = companiesPlacesQueues[k].queueId
                    if (  types.indexOf('app') >= 0 && ids.apps ) {
                        if ( developers[ ids.apps ] ) {
                            if ( !developersData['apps'] ) {
                                developersData['apps'] = {developerId: ids.apps, developerSet: _.cloneDeep( developers[ ids.apps ] )}
                            }
                            if ( action === 'remove' ) {
                                developersData['apps'].developerSet.accesses.takeTickets = this.deleteQueueToDeveloperAccount( _.cloneDeep(developersData['apps'].developerSet.accesses.takeTickets), companyId, placeId, queueId )
                            } else {
                                developersData['apps'].developerSet.accesses.takeTickets = this.addQueueToDeveloperAccount( _.cloneDeep(developersData['apps'].developerSet.accesses.takeTickets), companyId, placeId, queueId )
                            }
                        }
                    }
                    if ( types.indexOf('webapp') >= 0 && ids.webapps && ids.webapps !== ids.apps ) {
                        if ( developers[ ids.webapps ] ) {
                            if ( !developersData['webapps'] ) {
                                developersData['webapps'] = {developerId: ids.webapps, developerSet: _.cloneDeep( developers[ ids.webapps ] )}
                            }
                            if ( action === 'remove' ) {
                                developersData['webapps'].developerSet.accesses.takeTickets = this.deleteQueueToDeveloperAccount( _.cloneDeep(developersData['webapps'].developerSet.accesses.takeTickets), companyId, placeId, queueId )
                            } else {
                                developersData['webapps'].developerSet.accesses.takeTickets = this.addQueueToDeveloperAccount( _.cloneDeep(developersData['webapps'].developerSet.accesses.takeTickets), companyId, placeId, queueId )
                            }
                        }
                    }
                    if ( types.indexOf('terminal') >= 0 && ids.terminal) {
                        if ( developers[ ids.terminal ] ) {
                            if ( !developersData['terminal'] ) {
                                developersData['terminal'] = {developerId: ids.terminal, developerSet: _.cloneDeep( developers[ ids.terminal ] )}
                            }
                            if ( action === 'remove' ) {
                                developersData['terminal'].developerSet.accesses.takeTickets = this.deleteQueueToDeveloperAccount( _.cloneDeep(developersData['terminal'].developerSet.accesses.takeTickets), companyId, placeId, queueId )
                            } else {
                                developersData['terminal'].developerSet.accesses.takeTickets = this.addQueueToDeveloperAccount( _.cloneDeep(developersData['terminal'].developerSet.accesses.takeTickets), companyId, placeId, queueId )
                            }
                        }
                    }
                    if ( types.indexOf('endpoint') >= 0 && ids.endpoint) {
                        if ( developers[ ids.endpoint ] ) {
                            if ( !developersData['endpoint'] ) {
                                developersData['endpoint'] = {developerId: ids.endpoint, developerSet: _.cloneDeep( developers[ ids.endpoint ] )}
                            }
                            if ( action === 'remove' ) {
                                developersData['endpoint'].developerSet.accesses.takeTickets = this.deleteQueueToDeveloperAccount( _.cloneDeep(developersData['endpoint'].developerSet.accesses.takeTickets), companyId, placeId, queueId )
                                developersData['endpoint'].developerSet.accesses.validateTickets = this.deleteQueueToDeveloperAccount( _.cloneDeep(developersData['endpoint'].developerSet.accesses.validateTickets), companyId, placeId, queueId )
                            } else {
                                developersData['endpoint'].developerSet.accesses.takeTickets = this.addQueueToDeveloperAccount( _.cloneDeep(developersData['endpoint'].developerSet.accesses.takeTickets), companyId, placeId, queueId )
                                developersData['endpoint'].developerSet.accesses.validateTickets = this.addQueueToDeveloperAccount( _.cloneDeep(developersData['endpoint'].developerSet.accesses.validateTickets), companyId, placeId, queueId )
                            }
                        }
                    }
                    if ( types.indexOf('display') >= 0 && ids.display) {
                        if ( developers[ ids.display ] ) {
                            if ( !developersData['display'] ) {
                                developersData['display'] = {developerId: ids.display, developerSet: _.cloneDeep( developers[ ids.display ] )}
                            }
                            if ( action === 'remove' ) {
                                developersData['display'].developerSet.accesses.displayTickets = this.deleteQueueToDeveloperAccount( _.cloneDeep(developersData['display'].developerSet.accesses.displayTickets), companyId, placeId, queueId )
                            } else {
                                developersData['display'].developerSet.accesses.displayTickets = this.addQueueToDeveloperAccount( _.cloneDeep(developersData['display'].developerSet.accesses.displayTickets), companyId, placeId, queueId )
                            }
                        }
                    }
                }

                for ( const k in developersData ) {
                    editedDevelopersAccount.push( developersData[k] )
                }

                if ( editedDevelopersAccount.length > 0 ) {
                    return this.editDevelopers( editedDevelopersAccount ).pipe(
                        switchMap ( ( res: LocalCRUDRes ) => {
                            if ( res.success ) {
                                return of(true)
                            } else {
                                console.log('Error while adding queue to developerAccount')
                                console.log( res )
                                return of(false)
                            }
                        })
                    )
                } else {
                    return of(true)
                }
            })
        )*/
    }

    /**
     * Add add queue with his place and company on an access element of a developerAccount
     * @param access - the access object of a developer account
     * @param companyId - The ID of the company to add
     * @param placeId - the ID of the place to add
     * @param queueId - the ID of the queue to add
     * @return access - the updated object with the new values
     */
    private addQueueToDeveloperAccount ( access: DeveloperAccessCompany[], companyId: string, placeId: string, queueId: string): DeveloperAccessCompany[] {
        let companyIdIndex: number = -1
        let placeIdIndex: number = -1
        let queueIdIndex: number = -1

        // Search if the companies, place and queues are already in the access list
        for ( const companyIndex in access ) {
            if ( access[companyIndex].companyId === companyId ) {
                companyIdIndex = parseInt( companyIndex, 10 )
                for ( const placeIndex in access[companyIndex].places ) {
                    if ( access[companyIndex].places[placeIndex].placeId === placeId ) {
                        placeIdIndex = parseInt( placeIndex, 10 )
                        // Add the queue to the existing place access
                        if ( access[companyIndex].places[placeIndex].queues.indexOf( queueId ) < 0 ) {
                            access[companyIndex].places[placeIndex].queues.push( queueId )
                        }
                        queueIdIndex = access[companyIndex].places[placeIndex].queues.indexOf( queueId )
                    }
                }
            }
        }

        // The company is not listed in access : add it
        if ( companyIdIndex === -1 ) {
            access.push({companyId: companyId, places: []})
            companyIdIndex = access.length - 1
        }

        // The place is not listed in access : add it
        if ( placeIdIndex === -1 ) {
            access[companyIdIndex].places.push({placeId: placeId, queues: []})
            placeIdIndex = access[companyIdIndex].places.length - 1
        }

        // The queue is not listed in access : add it
        if ( queueIdIndex === -1 ) {
            access[companyIdIndex].places[placeIdIndex].queues.push( queueId )
        }

        return access
    }

    /**
     * Delete queue with his place and company on an access element of a developerAccount
     * @param access - the access object of a developer account
     * @param companyId - The ID of the company to add
     * @param placeId - the ID of the place to add
     * @param queueId - the ID of the queue to add
     * @return access - the updated object with the new values
     */
    private deleteQueueToDeveloperAccount ( access: DeveloperAccessCompany[], companyId: string, placeId: string, queueId?: string): DeveloperAccessCompany[] {
        const res = JSON.parse( JSON.stringify( access ) )
        for ( const companyIndex in access ) {
            if ( access[companyIndex].companyId === companyId ) {
                for ( const placeIndex in access[companyIndex].places ) {
                    if ( access[companyIndex].places[placeIndex].placeId === placeId ) {
                        if ( queueId ) {
                            if ( access[companyIndex].places[placeIndex].queues.indexOf( queueId ) >= 0 ) {
                                res[companyIndex].places[placeIndex].queues.splice( access[companyIndex].places[placeIndex].queues.indexOf( queueId ), 1 )
                            }
                            if ( res[companyIndex].places[placeIndex].queues.length === 0 ) {
                                res[companyIndex].places.splice( placeIndex, 1)
                                break
                            }
                        } else {
                            res[companyIndex].places.splice( placeIndex, 1)
                            break
                        }
                    }
                }
                if ( res[companyIndex].places.length === 0 ) {
                    res.splice( companyIndex, 1)
                    break
                }
            }
        }

        return res
    }
}
